import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';

import GetAppIcon from '@mui/icons-material/GetApp';
import Add from '@mui/icons-material/Add';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Check from '@mui/icons-material/Check';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import Warning from '@mui/icons-material/Warning';

import { GridClearIcon } from '@mui/x-data-grid';
import { useTheme } from 'styled-components';

// BMaps
import { LoadingScreen } from 'BMapsSrc/LoadingScreen';
import { WorkingIndicator, downloadData, truncateString } from 'BMapsSrc/ui/ui_utils';
import DialogContentSection from 'BMapsSrc/ui/common/DialogContentSection';
import { DialogSubmitButton, DialogCancelButton, DialogPrimaryButton } from 'BMapsSrc/ui/common/DialogActionButtons';
import { loadFiles } from 'BMapsSrc/paste_drag';
import { FoldingDataImportCase } from 'BMapsSrc/model';
import { UserActions } from 'BMapsSrc/cmds';
import { ResponseIds } from 'BMapsSrc/server/server_common';
import { withTooltip, wrapForDisabledTooltip } from 'BMapsSrc/ui/common/helpers';
import { TextFieldWithInfoTooltipAndFormik } from 'BMapsSrc/ui/common/TextFields/TextField';
import ExternalLink from 'BMapsSrc/ui/common/ExternalLink';
import { removeCRs } from 'BMapsSrc/util/mol_format_utils';
import BMapsTooltip from 'BMapsSrc/ui/BMapsTooltip';
import { TextArea } from 'BMapsSrc/ui/common/TextFields';
import ModalPane from 'BMapsSrc/ui/ModalPane';
import LinkLikeButton from 'BMapsSrc/ui/common/LinkLikeButton';
import { Backdrop } from '@mui/material';
import { Loader } from 'BMapsSrc/Loader';
import Select, { SelectWithInfoTooltip } from '../../../../common/Select';
import { DroppableTextAreaWithFormikNoError } from '../../base/TextAreaWithFormik';
import {
    InputFile, PlaceHolder, UploadButton, ImportErrors,
    FormControlLabelStyled,
} from '../../import_components';
import FormControlContainer from '../../base/FormControlContainer';
import {
    foldingPrograms, foldingSequenceTypes,
    extractSequences, guessSequenceTypeOne, guessSequenceTypeMulti, checkSequenceType,
    fullSequenceList,
    getExampleData,
} from './folding_utils';

// Helper components
const SpanWithInfoTooltip = withTooltip('span');
const CheckboxWithInfoTooltip = withTooltip(
    ({ label, ...rest }) => (
        <FormControlLabelStyled control={<Checkbox {...rest} />} label={label} />
    ),
    {
        IconWrapperProps: { style: { width: '3rem' } },
        WrapperProps: { style: { alignItems: 'center' } },
    }
);

/**
 * Return an event handler function for Escape key events, which will
 * execute an optional task. stopPropagation is called to prevent the
 * default escape key handling, which is bound to document.
 */
function getEscapeHandler(doFn) {
    return (e) => {
        if (e.key === 'Escape') {
            e.stopPropagation();
            if (doFn) doFn();
        }
    };
}

export default function ProteinFoldingTab({ selectProtein, errors, setErrors }) {
    const [receivedFoldingData, setReceivedFoldingData] = useState(null);
    const [addingNew, setAddingNew] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const theme = useTheme();

    async function submitFolding(formValues) {
        const foldingInfo = formValues.foldingInfo;

        const foldingData = {
            foldingProgramId: foldingInfo.foldingProgramId,
            foldingArgs: foldingInfo.extractFoldingArgs(formValues),
        };

        const loadOptions = {
            ligandSmiles: fullSequenceList(formValues.sequences)
                .filter((seq) => seq.type === 'ligand')
                .map((seq) => seq.data),
            preserveHydrogens: foldingData.foldingArgs.use_protein_prep,
        };

        console.log(`Sending folding request for ${foldingData.foldingProgramId}:`);
        console.log(JSON.stringify(foldingData.foldingArgs, null, 2));

        const handlers = {
            [ResponseIds.FoldStatus]: (packet) => {
                const msg = JSON.parse(packet)['folding-status'];
                let displayMsg = `Folding with ${foldingData.foldingProgramId}`;
                const queuePosition = msg.match(/queue position (?<queuePosition>\d+)/);
                if (queuePosition) {
                    displayMsg += ` ... Waiting, #${queuePosition.groups.queuePosition} in queue`;
                } else {
                    displayMsg += ` ... ${msg}`;
                }
                LoadingScreen.update(displayMsg);
            },
        };

        const { data, format, errors: foldingErrs } = await LoadingScreen.withLoadingScreen(
            UserActions.CallProteinFolding(foldingData, handlers),
            `Folding with ${foldingData.foldingProgramId}`
        );

        const name = formValues.moleculeName || null;

        const newMapCase = new FoldingDataImportCase({
            foldingData, data, format, name, loadOptions,
        });

        if (foldingErrs?.length > 0) {
            setErrors(foldingErrs);
        }

        if (data && format) {
            setReceivedFoldingData({ data, format });
            selectProtein(newMapCase, loadOptions);
        } else {
            setReceivedFoldingData(null);
        }
    }

    return (
        <Formik
            initialValues={{
                sequences: [],
                foldingInfo: foldingPrograms[0],
                fieldData: '',
                fieldType: '',
                moleculeName: '',
                useApplicationSpecific: false,
                applicationSpecificInput: '',
                useProteinPrep: false,
            }}
            onSubmit={submitFolding}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {({
                setFieldValue,
                setFieldError,
                values,
                getFieldProps,
                submitForm,
            }) => {
                const {
                    foldingInfo, useApplicationSpecific, applicationSpecificInput,
                    useProteinPrep, sequences, fieldData, moleculeName,
                } = values;

                function addInputItems(type, input=fieldData) {
                    const newSequences = extractSequences(input)
                        .map((line) => ({
                            type: type !== 'mixed' ? type : guessSequenceTypeOne(line),
                            data: line,
                            count: 1,
                        }))
                        .filter((seqItem) => seqItem.type); // Make sure we have a valid type
                    if (newSequences.length > 0) {
                        setFieldValue('sequences', [...sequences, ...newSequences]);
                        setFieldValue('fieldData', '');
                        setFieldError('fieldData', '');
                    } else {
                        setFieldError('fieldData', 'No sequences or smiles found in input');
                    }
                    closeAddComponent();
                }

                function closeAddComponent() {
                    setAddingNew(false);
                    setFieldValue('fieldData', '');
                }
                function openAddComponent() {
                    setAddingNew(true);
                }
                function clearSequences() {
                    setFieldValue('sequences', []);
                }
                function removeSequence(index) {
                    sequences.splice(index, 1);
                    setFieldValue('sequences', sequences);
                }
                function updateSequence(index, { type, data, count }) {
                    if (type !== undefined) sequences[index].type = type;
                    if (data !== undefined) sequences[index].data = data;
                    if (count !== undefined) sequences[index].count = count;
                    setFieldValue('sequences', sequences);
                }

                // Update the Submit button (disabled / tooltip) as necessary
                let submitBtnTooltip = '';
                let disabled = false;
                if (useApplicationSpecific) {
                    if (applicationSpecificInput === '') {
                        submitBtnTooltip = 'Add exact folding data, or uncheck the exact data option (under Advanced Parameters) to submit for folding';
                        disabled = true;
                        setConfirmation(false);
                    } else {
                        submitBtnTooltip = `Send exact data to ${foldingInfo.label}`;
                    }
                } else {
                    if (addingNew && fieldData) {
                        submitBtnTooltip = 'Include or cancel the folding inputs above before submission';
                        disabled = true;
                        setConfirmation(false);
                    } else if (sequences.length === 0) {
                        submitBtnTooltip = 'Add sequences or ligands to submit for folding';
                        disabled = true;
                        setConfirmation(false);
                    } else {
                        submitBtnTooltip = '';
                    }
                }
                return (
                    <Form
                        id="importProteinFoldingForm"
                        style={{
                            // Tweaks for supporting the modal-like "focused" AddFoldingInputArea.
                            // TabPanel.jsx uses p=3 for padding level.
                            // Override that padding in Tabs/index.js and add it here.
                            // Also, disable scrolling when the AddFoldingInputArea is open.
                            // This is because otherwise, it will only scroll when the mouse is over
                            // the AddFoldingInputArea, which is is confusing.
                            padding: theme.spacing(3),
                            overflow: addingNew ? 'hidden' : 'visible',
                            height: '100%',
                        }}
                    >
                        <DialogContentSection title="Protein Folding">
                            Predict the structure of a target or target-ligand complex
                            with an AI folding program.
                        </DialogContentSection>
                        <DialogContentSection title="1. Specify Folding Input" collapsible defaultExpand disablePaddingTop>
                            <Box>
                                <strong>Examples: </strong>
                                {getExampleData().map((data) => (
                                    <LinkLikeButton
                                        key={`example-${data.name}`}
                                        onClick={() => {
                                            setFieldValue('sequences', data.sequences);
                                            setFieldValue('moleculeName', data.name);
                                            setFieldValue('foldingInfo', foldingPrograms.find((item) => item.foldingProgramId === data.foldingProgramId));
                                            setFieldValue('useProteinPrep', data.useProteinPrep);
                                            setFieldValue('useApplicationSpecific', data.useApplicationSpecific);
                                            setFieldValue('applicationSpecificInput', data.applicationSpecificInput);
                                            setFieldValue('useCPU', data.useCPU);
                                        }}
                                    >
                                        {data.name}
                                    </LinkLikeButton>
                                ))}
                            </Box>
                            <FoldingInputTable
                                sequences={sequences}
                                removeSequence={removeSequence}
                                clearSequences={clearSequences}
                                updateSequence={updateSequence}
                            />
                            { addingNew ? (
                                <AddFoldingInputArea
                                    open={addingNew}
                                    input={fieldData}
                                    setInputValue={(value) => setFieldValue('fieldData', value)}
                                    inputFieldProps={getFieldProps('fieldData')}
                                    addFoldingInput={addInputItems}
                                    close={closeAddComponent}
                                />
                            ) : (
                                // Not a cancel button, but use the style
                                <DialogCancelButton
                                    sx={{ width: '100%' }}
                                    onClick={openAddComponent}
                                >
                                    <Add />
                                    {' '}
                                    Add Target Sequences or Ligands
                                </DialogCancelButton>
                            )}
                        </DialogContentSection>
                        <DialogContentSection title="2. Folding Options">
                            <SelectWithInfoTooltip
                                value={foldingInfo.foldingProgramId}
                                tooltip={''
                                    + 'Select the folding program to use. '
                                    + 'Different programs may have different performance and features. '
                                    + 'To utilize certain features like pocket constraints, use the exact data option under Advanced Parameters.'}
                                onChange={(e) => {
                                    setFieldValue('foldingInfo', foldingPrograms.find((item) => item.foldingProgramId === e.target.value));
                                }}
                                sx={{ marginTop: '1rem' }}
                                SelectProps={{ native: false }}
                                ComponentProps={{ label: 'Folding Program' }}
                                WrapperProps={{ sx: { alignItems: 'center' } }}
                                onKeyUp={getEscapeHandler()}
                            >
                                {foldingPrograms
                                    .filter((item) => !item.disabled)
                                    .map((item) => wrapForDisabledTooltip(
                                        <MenuItem
                                            value={item.foldingProgramId}
                                            key={item.foldingProgramId}
                                            disabled={item.disabled}
                                            title={item.disabled ? `Folding with ${item.label} is not available yet` : null}
                                        >
                                            {item.label}
                                        </MenuItem>
                                    ))}
                            </SelectWithInfoTooltip>
                            <TextFieldWithInfoTooltipAndFormik
                                {...getFieldProps('moleculeName')}
                                fullWidth
                                variant="outlined"
                                sx={{ marginTop: '1rem' }}
                                tooltip="A name for the system when the folding results are loaded into the 3D workspace"
                                ComponentProps={{ label: 'System Name (optional)' }}
                                WrapperProps={{ sx: { alignItems: 'center' } }}
                            />
                            <DialogContentSection
                                title="Advanced Parameters"
                                titleVariant="h8"
                                collapsible
                                disablePaddingTop
                                disablePaddingBottom
                            >
                                { foldingInfo.useCPU !== undefined && (
                                    <CheckboxWithInfoTooltip
                                        name="useCPU"
                                        id="useCPU"
                                        color="primary"
                                        checked={foldingInfo.useCPU}
                                        onChange={(e) => {
                                            setFieldValue('foldingInfo', {
                                                ...foldingInfo,
                                                useCPU: e.target.checked,
                                            });
                                        }}
                                        ComponentProps={{ label: 'Use CPU' }}
                                        tooltip="Use CPU instead of GPU for folding. This will take longer, but may succeed for structures that exhaust GPU memory."
                                    />
                                )}
                                {(!!Loader.AllowLabFeatures && (
                                    <CheckboxWithInfoTooltip
                                        {...getFieldProps('useProteinPrep')}
                                        disabled={!foldingInfo.allowProteinPrep}
                                        checked={!!foldingInfo.allowProteinPrep && useProteinPrep}
                                        ComponentProps={{ label: 'Run Protein Prep.' }}
                                        tooltip={`After folding, run protein preparation to add hydrogens ${foldingInfo.allowProteinPrep
                                            ? ''
                                            : `(Not Available for ${foldingInfo.label})`}`}
                                    />
                                ))}
                                <ExactDataOptions
                                    foldingInfo={foldingInfo}
                                    useApplicationSpecific={useApplicationSpecific}
                                    appSpecificData={applicationSpecificInput}
                                    checkboxProps={getFieldProps('useApplicationSpecific')}
                                    textareaProps={getFieldProps('applicationSpecificInput')}
                                    setValue={(value) => setFieldValue('applicationSpecificInput', value)}
                                />
                            </DialogContentSection>
                        </DialogContentSection>
                        <DialogContentSection title="3. Submit Folding Job">
                            <span style={{ fontStyle: 'italic' }}>
                                Folding should take 5-10 minutes and
                                the results will automatically load into the 3D workspace.
                            </span>
                            <BMapsTooltip title={submitBtnTooltip}>
                                {wrapForDisabledTooltip(
                                    <DialogPrimaryButton
                                        onClick={() => setConfirmation(true)}
                                        style={{ width: '100%' }}
                                        disabled={disabled}
                                    >
                                        Submit for Folding
                                    </DialogPrimaryButton>
                                )}
                            </BMapsTooltip>
                            {confirmation
                                && (
                                    <ConfirmationDialog
                                        open={confirmation}
                                        close={() => setConfirmation(false)}
                                        submit={submitForm}
                                        disabled={disabled}
                                    >
                                        {useApplicationSpecific
                                            ? (
                                                <DialogContentSection
                                                    title={`Exact Folding Input for ${foldingInfo.label}`}
                                                    titleVariant="h8"
                                                >
                                                    <ExactDataInput
                                                        disabled
                                                        appSpecificData={applicationSpecificInput}
                                                        foldingInfo={foldingInfo}
                                                        textareaProps={getFieldProps('applicationSpecificInput')}
                                                        setValue={(value) => setFieldValue('applicationSpecificInput', value)}
                                                    />
                                                </DialogContentSection>
                                            ) : (
                                                <DialogContentSection
                                                    title="Folding Input"
                                                    titleVariant="h8"
                                                >
                                                    <FoldingInputTable
                                                        sequences={sequences}
                                                        removeSequence={removeSequence}
                                                        clearSequences={clearSequences}
                                                        updateSequence={updateSequence}
                                                    />
                                                </DialogContentSection>
                                            )}
                                        <DialogContentSection
                                            title="Parameters"
                                            titleVariant="h8"
                                        >
                                            <ConfirmationTable
                                                data={{
                                                    'Folding Program': foldingInfo.label,
                                                    'System Name': moleculeName || undefined,
                                                    'Use CPU': foldingInfo.useCPU ? 'Yes' : undefined,
                                                    'Use Protein Prep': useProteinPrep && foldingInfo.allowProteinPrep ? 'Yes' : undefined,
                                                }}
                                            />
                                        </DialogContentSection>
                                    </ConfirmationDialog>
                                )}
                        </DialogContentSection>
                        <FoldingDataDownload
                            foldingInfo={foldingInfo}
                            foldingData={receivedFoldingData}
                        />
                        {errors && <ImportErrors errors={errors} /> }
                    </Form>
                );
            }}
        </Formik>
    );
}

/**
 * Component for downloading the file returned by the folding program
 */
function FoldingDataDownload({ foldingInfo, foldingData }) {
    if (!foldingData) {
        return false;
    }

    const filename = `BMaps_${foldingInfo.foldingProgramId}_results.${foldingData.format}`;
    return (
        <>
            Download folding results
            {' '}
            <DownloadButton
                data={foldingData.data}
                filename={filename}
                tooltip="Download folding results"
            />
        </>
    );
}

function ConfirmationDialog({
    open, close, children, submit, disabled,
}) {
    return (
        <ModalPane
            visible={open && !disabled}
            title="Confirm Folding Request"
            close={close}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
            }}
            DialogProps={{
                onKeyUp: getEscapeHandler(close),
            }}
        >
            <Box sx={{ marginTop: '1rem', overflow: 'auto' }}>
                {children}
            </Box>
            <Box sx={{
                display: 'flex', flexDirection: 'row', gap: '1rem', paddingTop: '1rem',
            }}
            >
                <DialogSubmitButton onClick={() => { close(); submit(); }} sx={{ flexGrow: 1 }}>
                    Submit
                </DialogSubmitButton>
                <DialogCancelButton onClick={close} sx={{ flexGrow: 1 }}>
                    Cancel
                </DialogCancelButton>
            </Box>

        </ModalPane>
    );
}

/**
 * Component for a generic download button. Candidate for moving to a common location.
 */
function DownloadButton({ data, filename='download.txt', tooltip }) {
    return (
        <BMapsTooltip title={tooltip}>
            <IconButton aria-label="download" disabled={!data}>
                <GetAppIcon onClick={
                    () => {
                        if (data) {
                            downloadData(filename, data);
                        }
                    }
                }
                />
            </IconButton>
        </BMapsTooltip>
    );
}

/**
 * Component for the input area to add folding components
 */
function AddFoldingInputArea({
    input, inputFieldProps, setInputValue,
    addFoldingInput, close, open,
}) {
    const theme = useTheme();
    const ref = useRef(null);
    const [type, setType] = useState(guessSequenceTypeMulti(input));
    useEffect(() => { setType(guessSequenceTypeMulti(input)); }, [input]);
    useEffect(() => { ref.current?.scrollIntoView(); }, [open]);

    return (
        <>
            <Backdrop
                open={open}
                onClick={close}
                sx={{
                    // there is a zIndex on the table, so we need to place both this
                    // and the text area on top of it
                    zIndex: 2,
                }}
            />
            <Paper
                ref={ref}
                sx={{
                    padding: '0rem 1rem 1rem 1rem',
                    borderColor: theme.palette.primary.main,
                    position: 'relative',
                    zIndex: 2,
                }}
            >
                <Box sx={{
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
                }}
                >
                    <SpanWithInfoTooltip
                        tooltip={(
                            <>
                                Enter protein, DNA, RNA sequences or ligand smiles strings into
                                the text input box, one per line.
                                <br />
                                FASTA with header lines (starting with &quot;&gt;&quot;)
                                and comments is ok.
                                <br />
                                Then click &quot;Include...&quot; to prepare the input for folding.
                            </>
                        )}
                        style={{
                            fontWeight: 'bold',
                        }}
                        WrapperProps={{ style: { alignItems: 'center' } }}
                        IconWrapperProps={{
                            style: {
                                width: '3rem', // match default width
                                justifyContent: 'center',
                            },
                        }}
                    >
                        Add Sequence / Ligand
                    </SpanWithInfoTooltip>
                    <IconButton onClick={close}>
                        <GridClearIcon />
                    </IconButton>
                </Box>
                <FoldingInputBox
                    value={input}
                    close={close}
                    type={type}
                    fieldProps={inputFieldProps}
                    receiveFileData={(newValue) => {
                        // When dealing with a file, directly stage if we have valid data,
                        if (guessSequenceTypeMulti(newValue)) {
                            addFoldingInput('mixed', newValue);
                        } else {
                            setInputValue(newValue);
                        }
                    }}
                />
                <AddSequenceButtons
                    input={input}
                    type={type}
                    setType={setType}
                    addSequences={addFoldingInput}
                    close={close}
                />
            </Paper>
        </>
    );
}

/**
 * Component for the buttons to add folding components.
 * Labels and tooltips adapt to the data in the input box.
 */
function AddSequenceButtons({
    input, type, setType,
    addSequences, close,
}) {
    const options = Object.values(foldingSequenceTypes);

    const inputSequences = extractSequences(input);
    let disabledMessage = '';
    if (!input.trim() || inputSequences.length === 0) {
        disabledMessage = 'Enter a sequence or smiles string to include in the folding input';
    } else if (!type) {
        disabledMessage = 'No valid sequence or smiles is detected in the input box.';
    }

    const typesToAdd = inputSequences.reduce(
        (acc, nextLine) => {
            const guess = guessSequenceTypeOne(nextLine);
            if (!guess) return acc;
            const newCount = acc[guess] != null ? acc[guess] + 1 : 1;
            return { ...acc, [guess]: newCount };
        }, {}
    );
    const totalFound = Object.values(typesToAdd).reduce((acc, next) => acc + next, 0);
    const disabled = !!disabledMessage;
    const typeInfo = foldingSequenceTypes[type];
    let addLabel = `Include ${foldingSequenceTypes.mixed.unit}`;
    let addTooltip = disabledMessage;
    if (typeInfo) {
        if (!disabled) {
            const total = type === 'mixed' ? totalFound : inputSequences.length;
            const totalLabelToUse = total > 1 ? typeInfo.unitPlural : typeInfo.unit;
            addLabel = `Include ${total} ${totalLabelToUse}`;
            if (type === 'mixed') {
                const labels = Object.entries(typesToAdd).map(([typeName, count]) => {
                    const { unit, unitPlural } = foldingSequenceTypes[typeName];
                    const labelToUse = count > 1 ? unitPlural : unit;
                    return `${count} ${labelToUse}`;
                });
                addTooltip = `${typeInfo.title} (${labels.join(', ')})`;
            } else {
                addTooltip = `${typeInfo.title}`;
                const unrecognized = inputSequences.filter((seq) => !checkSequenceType(seq, type));
                if (unrecognized.length > 0) {
                    const unrecognizedLabel = unrecognized.length === 1 ? 'input line does' : 'input lines do';
                    addTooltip += `. Note: ${unrecognized.length} ${unrecognizedLabel} not match the expected format for ${typeInfo.unitPlural}. Please double-check before submission.`;
                }
            }
        } else {
            addLabel = `Include ${typeInfo.unit}`;
        }
    }

    const emptyText = totalFound > 0 ? 'Select Component Type' : 'No valid sequence type detected';
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            gap: '0.5rem',
        }}
        >
            <Select
                value={type || ''}
                label="Component Type"
                onChange={(e) => setType(e.target.value)}
                sx={{ flex: '1 1 auto', width: 'unset' }}
                SelectProps={{ native: false }}
                onKeyUp={getEscapeHandler()}
            >
                <MenuItem value=""><em>{emptyText}</em></MenuItem>
                {options.map((option) => (
                    <MenuItem
                        key={option.id}
                        value={option.id}
                        disabled={option.id === 'mixed' && totalFound === 0}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            <BMapsTooltip title={addTooltip}>
                {wrapForDisabledTooltip(
                    <DialogSubmitButton
                        onClick={() => addSequences(type)}
                        disabled={disabled}
                        sx={{ flex: '1 1 auto' }}
                    >
                        {addLabel}
                    </DialogSubmitButton>,
                    { WrapperProps: { style: { display: 'flex', flex: '1 1 auto' } } }
                )}
            </BMapsTooltip>
            <DialogCancelButton
                onClick={close}
                sx={{ flex: '1 1 auto' }}
            >
                Cancel
            </DialogCancelButton>
        </Box>
    );
}

/**
 * Component for the text area to receive folding inputs
 */
function FoldingInputBox({
    value, type, close,
    fieldProps, receiveFileData,
}) {
    const [loading, setLoading] = useState(false);
    const uploadBtnRef = useRef(null);
    function handleUploadClicked() {
        uploadBtnRef.current.children[0].click();
    }
    async function stageSequenceFiles(files) {
        setLoading(true);
        const text = await loadFiles(files);
        setLoading(false);
        receiveFileData(text.join('\n'));
    }

    const entityDesc = foldingSequenceTypes[type]?.placeholder || 'sequences and/or ligand smiles';
    const fileDesc = foldingSequenceTypes[type]?.filetypes?.join(', ') || 'fasta';

    return (
        <Box>
            <PlaceHolder show={value === ''}>
                { loading
                    ? <WorkingIndicator />
                    : (
                        <>
                            Enter
                            {' '}
                            {entityDesc}
                            ,
                            one per line.
                            <br />
                            Or
                            {' '}
                            Drag & Drop or
                            {' '}
                            <UploadButton
                                disableRipple
                                onClick={handleUploadClicked}
                            >
                                upload
                            </UploadButton>
                            {' '}
                            {fileDesc}
                            {' '}
                            or other text files with
                            {' '}
                            {entityDesc}
                            .
                        </>
                    )}
            </PlaceHolder>
            <DroppableTextAreaWithFormikNoError
                {...fieldProps}
                autoFocus
                multiline
                minRows={1}
                onDrop={(files) => stageSequenceFiles(files)}
                sx={{
                    '& textarea': {
                        resize: 'vertical',
                        minHeight: '6rem',
                    },
                }}
                onKeyUp={getEscapeHandler(close)}
            />
            <FormControlContainer>
                <InputFile
                    ref={uploadBtnRef}
                    type="file"
                    onChange={
                    (e) => stageSequenceFiles(e.target.files)
                }
                />
            </FormControlContainer>
        </Box>
    );
}

function TextOrTooltip({ text, limit }) {
    const display = <span>{truncateString(text, limit, 0)}</span>;
    return text.length <= limit
        ? display
        : <BMapsTooltip title={text}>{display}</BMapsTooltip>;
}

function FoldingInputTable({
    sequences, clearSequences, removeSequence, updateSequence,
}) {
    const theme = useTheme();
    if (sequences.length === 0) return false;
    return (
        <TableContainer component={Paper} sx={{ maxHeight: '20rem' }}>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="Folding input items table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell align="left">Data</TableCell>
                        <TableCell align="left">Count</TableCell>
                        <TableCell align="right">
                            <BMapsTooltip title="Remove everything from folding input">
                                <Button
                                    onClick={clearSequences}
                                    endIcon={(
                                        <Delete
                                            sx={{ color: theme.palette.action.active }}
                                            style={{ fontSize: theme.typography.pxToRem(20) }}
                                        />
                                    )}
                                    color="neutral"
                                    sx={{ textTransform: 'none' }}
                                >
                                    Clear All
                                </Button>
                            </BMapsTooltip>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sequences.map((sequence, index) => (
                        <FoldingInputRow
                            key={`sequence-${sequence.type}-${index.toString()}`}
                            sequence={sequence}
                            index={index}
                            removeSequence={removeSequence}
                            updateSequence={updateSequence}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function FoldingInputRow({
    sequence, index, removeSequence, updateSequence,
}) {
    const [editingType, setEditingType] = useState(false);
    const [editingSeq, setEditingSeq] = useState(false);
    let warning = '';
    if (!checkSequenceType(sequence.data, sequence.type)) {
        warning = `The provided data doesn't match the expected format for the selected type (${sequence.type}). Please double-check before submission.`;
    }

    return (
        <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell align="left">
                {!editingType
                    ? (
                        <>
                            <BMapsTooltip title="Change component type">
                                <IconButton
                                    onClick={() => setEditingType(true)}
                                >
                                    <Edit />
                                </IconButton>
                            </BMapsTooltip>
                            {' '}
                            {sequence.type.toUpperCase()}
                        </>
                    ) : (
                        <Select
                            size="small"
                            value={sequence.type}
                            onChange={(e) => { updateSequence(index, { type: e.target.value }); }}
                            SelectProps={{
                                native: false,
                                open: true,
                                onClose: () => setEditingType(false),
                                MenuProps: {
                                    PaperProps: {
                                        onKeyDown: getEscapeHandler(),
                                        onKeyUp: getEscapeHandler(() => setEditingType(false)),
                                    },
                                },
                            }}
                        >
                            <MenuItem value="protein">Protein</MenuItem>
                            <MenuItem value="dna">DNA</MenuItem>
                            <MenuItem value="rna">RNA</MenuItem>
                            <MenuItem value="ligand">Ligand</MenuItem>
                        </Select>
                    )}
            </TableCell>
            <TableCell align="left">
                { !editingSeq ? (
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <BMapsTooltip title="Edit component data">
                            <IconButton onClick={() => setEditingSeq(true)}>
                                <Edit />
                            </IconButton>
                        </BMapsTooltip>
                        <TextOrTooltip
                            text={sequence.data}
                            limit={40}
                        />
                    </Box>
                ) : (
                    <EditInPlaceInput
                        Component={TextArea}
                        value={sequence.data}
                        setValue={(value) => {
                            updateSequence(index, { data: value });
                            setEditingSeq(false);
                        }}
                        stopEditing={() => setEditingSeq(false)}
                    />
                )}
            </TableCell>
            <TableCell align="left">
                <Select
                    size="small"
                    value={sequence.count}
                    SelectProps={{
                        native: false,
                    }}
                    onChange={(e) => { updateSequence(index, { count: e.target.value }); }}
                    onKeyUp={getEscapeHandler()}
                >
                    {Array.from({ length: 10 }, (v, i) => (
                        <MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>
                    ))}
                </Select>
            </TableCell>
            <TableCell align="right">
                {!!warning && (
                    <BMapsTooltip title={warning}>
                        <IconButton sx={{ color: 'red' }}><Warning /></IconButton>
                    </BMapsTooltip>
                )}
                <BMapsTooltip title="Remove component from folding input">
                    <IconButton onClick={() => removeSequence(index)}>
                        <GridClearIcon />
                    </IconButton>
                </BMapsTooltip>
            </TableCell>
        </TableRow>
    );
}

function EditInPlaceInput({
    value, setValue, stopEditing, Component,
}) {
    const [localValue, setLocalValue] = useState(value);
    const disabled = localValue === '' || localValue === value;
    const checkTitle = disabled ? 'Enter a new value to save changes' : 'Save changes';
    return (
        <ClickAwayListener onClickAway={stopEditing}>
            <Box
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                onKeyUp={getEscapeHandler(stopEditing)}
            >
                <Component
                    value={localValue}
                    onChange={(e) => setLocalValue(e.target.value)}
                    autoFocus
                />
                <BMapsTooltip title={checkTitle}>
                    {wrapForDisabledTooltip(
                        <IconButton onClick={() => setValue(localValue)} disabled={disabled}>
                            <Check />
                        </IconButton>
                    )}
                </BMapsTooltip>
                <BMapsTooltip title="Cancel edit" arrow>
                    <IconButton onClick={stopEditing}>
                        <ArrowBack />
                    </IconButton>
                </BMapsTooltip>
            </Box>
        </ClickAwayListener>
    );
}

/**
 * Component with a checkbox and textarea for the Exact Data for the folding program
 */
function ExactDataOptions({
    foldingInfo,
    checkboxProps, textareaProps,
    useApplicationSpecific, appSpecificData,
    setValue,
}) {
    return (
        <>
            <CheckboxWithInfoTooltip
                {...checkboxProps}
                checked={useApplicationSpecific}
                ComponentProps={{ label: foldingInfo.appSpecificLabel }}
                tooltip={(
                    <>
                        The contents of the &quot;exact data&quot; field will be sent as-is to
                        {' '}
                        {foldingInfo.label}
                        , instead of using the Folding Input items above.
                        <br />
                        This can be useful for utilizing certain features like pocket constraints,
                        but requires understanding the expected format for
                        {' '}
                        {foldingInfo.label}
                        . See the
                        {' '}
                        { foldingInfo.documentationUrl
                            ? (
                                <ExternalLink
                                    link={foldingInfo.documentationUrl}
                                    skipIcon
                                    style={{ color: 'var(--marketing-blue)' }}
                                >
                                    {foldingInfo.label}
                                    {' '}
                                    documentation
                                </ExternalLink>
                            )
                            : `${foldingInfo.label} documentation`}
                        {' '}
                        for more information.
                    </>
                )}
            />
            { useApplicationSpecific && (
                <>
                    <span style={{ fontStyle: 'italic' }}>
                        Note: when using this exact data field,
                        any sequences and ligands added above will be ignored.
                    </span>
                    <ExactDataInput
                        appSpecificData={appSpecificData}
                        foldingInfo={foldingInfo}
                        textareaProps={textareaProps}
                        setValue={setValue}
                    />
                </>
            )}
        </>
    );
}

function ConfirmationTable({ data }) {
    return (
        <TableContainer sx={{ marginBottom: '1rem' }}>
            <Table sx={{ minWidth: 650 }} aria-label="Parameter listing" size="small">
                <TableBody>
                    {Object.entries(data)
                        .filter(([_, value]) => value !== undefined)
                        .map(([key, value]) => (
                            <TableRow key={key}>
                                <TableCell component="th" scope="row">
                                    {key}
                                </TableCell>
                                <TableCell align="left">
                                    {value}
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function ExactDataInput({
    appSpecificData, foldingInfo, setValue, textareaProps, disabled=false,
}) {
    const [loading, setLoading] = useState(false);
    const uploadBtnRef = useRef(null);
    function handleUploadBtnClicked() {
        uploadBtnRef.current.children[0].click();
    }
    async function stageFiles(files) {
        setLoading(true);
        const text = await loadFiles(files);
        setLoading(false);
        setValue(removeCRs(text.join('\n')));
    }
    return (
        <Box>
            <PlaceHolder show={appSpecificData === ''}>
                { loading ? <WorkingIndicator /> : (
                    <>
                        Enter exact folding data in a format expected by
                        {' '}
                        {foldingInfo.documentationUrl
                            ? (
                                <ExternalLink
                                    link={foldingInfo.documentationUrl}
                                    style={{ pointerEvents: 'all' }}
                                >
                                    {foldingInfo.label}
                                </ExternalLink>
                            ): foldingInfo.label}
                        .
                        <br />
                        Or
                        {' '}
                        Drag & Drop or
                        {' '}
                        <UploadButton disableRipple onClick={handleUploadBtnClicked}>
                            upload
                        </UploadButton>
                        {' '}
                        a file with the data for the folding request.
                    </>
                )}
            </PlaceHolder>
            <DroppableTextAreaWithFormikNoError
                {...textareaProps}
                disabled={disabled}
                autoFocus
                multiline
                minRows={1}
                onDrop={(files) => stageFiles(files)}
                sx={{
                    '& textarea': {
                        resize: 'vertical',
                        minHeight: '6rem',
                    },
                }}
            />
            <FormControlContainer>
                <InputFile
                    ref={uploadBtnRef}
                    type="file"
                    onChange={(e) => stageFiles(e.target.files)}
                />
            </FormControlContainer>
        </Box>
    );
}
